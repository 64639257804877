<template>
  <div id="gpsData" v-loading.fullscreen.lock="loading" element-loading-text="请求中，请稍后" element-loading-background="rgba(255, 255, 255, 0.7)">
    <!-- 地图 -->
    <baidu-map class="all-map" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" @ready="handlerGps">
      <!-- 左上缩放控件 -->
      <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>

      <!-- 又上地图类型 -->
      <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_RIGHT">
      </bm-map-type>
      <!-- 右下定位 -->
      <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true">
      </bm-geolocation>
      <!-- marker -->
      <bm-marker v-for="item of dataArr" :key="item.uid" :position="{lng: item.lng, lat: item.lat}" @click="openDialog(item)">
        <!-- :icon="{url: require('../../assets/device.png'), size: {width: 30, height: 30}}" -->
        <!-- animation="BMAP_ANIMATION_BOUNCE" -->
        <!-- <bm-info-window :show="item.infoWindow" @close="item.infoWindow=false" :width="infoWindow.width"
					:height="infoWindow.height">
					<div style="height: 500px; overflow: auto;">
						<DeviceInfo ref="info" :map="false" :data="deviceInfo"></DeviceInfo>
					</div>
				</bm-info-window> -->
        <bm-label :content="item.name?item.name:item.uid" :position="{lng: item.lng, lat: item.lat}" :offset="{width: 20, height: -20}" :labelStyle="{color: 'red', fontSize : '14px'}" title="点击可查看详情" />
      </bm-marker>
    </baidu-map>
    <!-- 达标统计 -->
    <div class="open-count" v-show="isCount">
      <el-button type="primary" icon="el-icon-pie-chart" circle size="medium" @click="isCount=!isCount">
      </el-button>
    </div>
    <div class="count" v-show="isCount">
      <div class="chart">
        <div class="title">
          <span>数据达标统计</span>
          <i class="el-icon-close" @click="isCount=!isCount"></i>
        </div>
        <div id="barChart" style="width: 100%; height: 100%;"></div>
      </div>
      <div class="table">
        <el-table :data="deviceDataArr" border>
          <el-table-column prop="name" label="设备" show-overflow-tooltip></el-table-column>
          <el-table-column label="是否达标">
            <template slot-scope="scope">
              {{scope.row.datas[0].value < 80 ?'达标':'未达标'}}
              <!-- {{scope.row}} -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- info-window dialog -->
    <Dialog :data="infoWindowDialog">
      <template>
        <DeviceInfo ref="deviceInfo" :map="false" :data="deviceInfo"></DeviceInfo>
      </template>
    </Dialog>
  </div>
</template>

<script>
import DeviceInfo from '../../components/DeviceInfo.vue';
import Dialog from '../../components/Dialog.vue';

export default {
  data() {
    return {
      map: null,
      center: {
        lng: 116.401394,
        lat: 39.916042
      },
      infoWindow: {
        width: 800,
        height: 500
      },
      zoom: 15,
      dataArr: [],
      deviceDataArr: [],
      deviceInfo: {},
      // info-window dialog
      infoWindowDialog: {
        show: false,
        title: '设备详情',
        width: '90%'
      },
      isCount: false, //右侧统计图表
      isCurpageRequest: true, //是否为本页面请求，DeviceInfo请求后的回调本页面不予处理
      loading: false,
      is_screen: false
    }
  },
  components: {
    DeviceInfo,
    Dialog
  },
  computed: {
    devices() {
      return this.$store.getters.getCurDevices;
    }
  },
  watch: {
    devices() {
      this.gpsDataRequest();
    }
  },
  methods: {
    handlerGps({
      BMap,
      map
    }) {
      this.map = map;
      if (this.dataArr.length == 0) {
        return;
      }
      var view = map.getViewport(eval(this.dataArr));
      this.zoom = view.zoom;
      this.center = view.center;
    },
    openDialog(item) {
      this.infoWindowDialog.show = true;
      let device = this.common.cFindObj(this.devices, "id", item.uid);
      if (device != null) {
        setTimeout(() => {
          this.deviceInfo = device;
          this.$refs.deviceInfo.setChartData();
        }, 100);
      }
    },
    drawBar() {
      let echarts = require('echarts');
      let chart = echarts.init(document.getElementById('barChart'));
      let option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'right',
        },
        series: [{
          type: 'pie',
          radius: '50%',
          label: {
            show: false
          },
          labelLine: {
            show: false
          },
          data: [{
            value: 80,
            name: '已达标'
          },
          {
            value: 35,
            name: '未达标'
          },
          {
            value: 10,
            name: '无数据'
          }
          ]
        }]
      };
      chart.setOption(option);
    },
    // 坐标数据请求
    gpsDataRequest() {
      let uids = "";
      this.devices.forEach((item) => {
        uids += item.id + ","
      })
      let json = {
        "cmd": "dataStore",
        "id": uids,
        "tag": "gps"
      }
      this.ws.send(json);
      this.isCurpageRequest = true;
    },
    //全屏
    screen() {
      //隐藏左侧列表和顶部菜单标题
      this.is_screen = !this.is_screen
      this.$store.commit("settingStore/SET_FULLSCREEN", this.is_screen);
      //浏览器全屏显示
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
  },
  mounted() {
    this.gpsDataRequest();

    this.ws.addCallback('dataStoreAck', (res) => {
      if (res.tag == "gps" && this.isCurpageRequest) {
        if (!this.common.cGetResult(res)) {
          return;
        }
        let dataArr = new Array();
        for (let item of res.items) {
          let data = item.data;
          if (JSON.stringify(data) != "{}") {
            let location = data.location;
            if (!location || location.indexOf(",") == -1) {
              break;
            }
            let name = "";
            let device = this.common.cFindObj(this.devices, "id", item.id);
            if (device != null) {
              name = device.title;
            }
            let locationArr = location.split(",");
            dataArr.push({
              uid: item.id,
              name: name,
              lng: locationArr[0],
              lat: locationArr[1]
            })
          }
        }
        this.dataArr = dataArr;
        if (this.map != null) {
          var view = this.map.getViewport(eval(this.dataArr));
          this.zoom = view.zoom;
          this.center = view.center;
        }
      }
      this.isCurpageRequest = false;
    })

    // 右侧数据达标统计测试数据
    // this.deviceDataArr = this.common.cGetDeviceData();
    // this.drawBar();
  }
}
</script>

<style lang="scss">
#gpsData {
  .all-map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  // 统计
  .open-count {
    position: absolute;
    top: 10px;
    right: 100px;
  }

  .count {
    height: 50%;
    min-height: 500px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 220px;
    background-color: $color_white;
    border: 1px solid $border_color;
    display: flex;
    flex-direction: column;

    .chart {
      height: 220px;
      flex-shrink: 0;

      .title {
        line-height: 32px;
        padding: 0 10px;
        background-color: $color_theme;
        color: $color_white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        i {
          &:hover {
            @include c-hover;
          }
        }
      }
    }

    .table {
      flex: auto;
      overflow: auto;

      .el-table th,
      .el-table td {
        font-size: 12px !important;
        padding: 2px !important;
      }
    }
  }

  // 重写dialog样式
  .el-dialog {
    height: 90%;
    overflow: auto;
    margin-top: 70px !important;
    margin-bottom: 0 !important;
  }

  .el-dialog__body {
    padding: 0 2%;
  }

  .el-dialog__footer {
    display: none;
  }

  // 地图info-window样式
  .bm-info-window {
    width: 1000px;
  }
}
</style>
